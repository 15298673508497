const Flickity = require("flickity");
const Velocity = require("velocity-animate");
const Plyr = require("plyr");

class InitPage {
    constructor() {
        this.pageLoaded = false;
        this.viewportScrolling = false;
        this.overlayGallery = null;

        this.galCnt = 0;
        this.gals = [];
    }

    setup() {
        (async() => {
            try {

                await this.setupGalleries();
                await this.setupVideoPlyr();

                await global.resizing.resizeHandler();

            } catch (error) {
                console.error('(FROM ASYNC/AWAIT) Error cause is:', error);
            }
        })();

        $('body').addClass('page-loaded');
        this.pageLoaded = true;

        let scope = this;

    }

    setupVideoPlyr(){
        var players = Plyr.setup('.plyr',{
            controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'airplay', 'fullscreen'],
            toggleInvert: false,
            fullscreen: { enabled: true, fallback: true, iosNative: true },
            storage: { enabled: true, key: 'tb-b-sm-plyr' },
            vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false, loop: false, autoplay: false },
            youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, autoplay: 0 }
        });
    }

    resetForcedTextColour(){
        $('html').removeClass('blackCopy');
    }
    setForcedTextColour(){
        $('html').addClass('blackCopy');
    }

    setupGalleries() {
        this.destroyGalleries();

        let scope = this;

        if($('.galleryCounter').length){
            $('.galleryCounter').each(function(){
                var galCountTotal = $(this).parent().find('.gallerySlide').length;
                if($('body').hasClass('page-homepage')){
                    galCountTotal = $(this).parent().parent().parent().find('.gallerySlide').length;
                }
                $(this).find('.galCountTotal').html(""+galCountTotal);
            });
        }
        $('.galCount').html("1");
        $('.galCountSeparator').html("/");

        $('.fullbleedGallery .gallerySlideTitle').html($('.fullbleedGallery .gallerySlide').eq(0).data('title'));

        if($('.fullbleedGallery .gallerySlide').eq(0).data('textcol') == "black"){
            scope.setForcedTextColour();
        }

        if($('.js-fullbleedGallery').length > 0){
            var gal, gallery;
            $('.js-fullbleedGallery').each(function(){

                gal = $(this);

                gallery = new Flickity('.js-fullbleedGallery', {
                    cellSelector: '.gallerySlide',
                    setGallerySize: false,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    bgLazyLoad: 2,
                    prevNextButtons: true,
                    pageDots: false,
                    autoPlay: 7000,
                    pauseAutoPlayOnHover: false,
                    wrapAround: true,
                    touchVerticalScroll: false
                });
                gallery.resize();
                gallery.focus();
                scope.gals.push(gallery);

                gallery.on( 'dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                gallery.on( 'dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

                gallery.on( 'change', function( index ){
                    var slide = gal.find('.gallerySlide')[index];
                    $('.fullbleedGallery .galCount').html(""+ (index + 1));
                    $('.fullbleedGallery .gallerySlideTitle').html($(slide).data('title'));

                    var url = $(slide).data('url');
                    if(url && url != ""){
                        $('.fullbleedGallery .gallerySlideLink').attr("href",url);
                        $('.fullbleedGallery .gallerySlideLink').addClass('shown');
                    }else{
                        $('.fullbleedGallery .gallerySlideLink').attr("href","");
                        $('.fullbleedGallery .gallerySlideLink').removeClass('shown');
                    }

                    scope.resetForcedTextColour();
                    if($(slide).data('textcol') == "black"){
                        scope.setForcedTextColour();
                    }

                    global.resizing.resizeHandler();
                });

                gallery.on( 'staticClick', function( event, pointer, cellElement, cellIndex ){
                   if ( !cellElement ) {
                        return;
                    }
                    gallery.next();
                });

            });
        }

        if($('.gallery').length > 0){
            $('.gallery').each(function(){

                gal = $(this);
                const uniqueClassName = 'gal-'+scope.galCnt;
                scope.galCnt++;
                gal.addClass(uniqueClassName);

                var gallery = new Flickity('.'+uniqueClassName, {
                    cellSelector: '.gallerySlide',
                    setGallerySize: false,
                    contain: false,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    //bgLazyLoad: 2,
                    prevNextButtons: false,
                    pageDots: true,
                    autoPlay: true
                });
                gallery.resize();
                scope.gals.push(gallery);
                //gallery.focus();

                /*$('.viewport-gallery-slide').on('click', function(e){
                    e.stopPropagation();
                    e.preventDefault();

                    return false;
                });
                gallery.on( 'staticClick.flickity', function( event, pointer, cellElement ) {
                    var newUrl = $(cellElement).attr('href');
                    if(newUrl){
                        linkClicked = true;
                        Barba.Pjax.goTo(newUrl);
                    }
                });*/

            });
        }

    }

    destroyGalleries(){
        if(this.gals.length){
            for (var i = this.gals.length - 1; i >= 0; i--) {
                this.gals[i].destroy();
            }
            this.gals = [];
        }
        this.overlayGallery = null;
    }
}

module.exports = { InitPage };
