const Barba = require("barba.js");

class PageLoading {
    constructor() {
        this.linkClicked = false;
        this.backClicked = false;
    }

    setupBarba() {
        Barba.Pjax.Dom.wrapperId = "loading-container";
        Barba.Pjax.Dom.containerClass = "loaded-content";
        Barba.Utils.xhrTimeout = 7000;
        Barba.Pjax.start();
        Barba.Prefetch.init();

        Barba.Dispatcher.on('linkClicked', function() {
            $('html').addClass('pjax-loading');
            $('html').removeClass('menuHovering');
            $('html').removeClass('workGalleryOverlayShown');
            $('.menu-el').removeClass('hover');

            this.linkClicked = true;
        });
        Barba.Dispatcher.on('initStateChange', function() {
            if (!this.linkClicked) {
                this.backClicked = true;
            }
        });
        Barba.Dispatcher.on('newPageReady', function() {
            $('body').removeClass('force-reveal');
            $('html').removeClass('no-scroll');
            global.utils.scrollToTop(1);
            global.initPage.resetForcedTextColour();
        });
        Barba.Dispatcher.on('transitionCompleted', function() {
            global.pageReady.setup();
            if (this.backClicked) {
                $('body').addClass('force-reveal');
            }
            global.initPage.setup();
            $('html').removeClass('pjax-loading');
            this.linkClicked = false;
            this.backClicked = false;
        });

        var FadeTransition = Barba.BaseTransition.extend({
            start: function() {
                Promise
                    .all([this.newContainerLoading, this.fadeOut()])
                    .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
                return $(this.oldContainer).animate({ opacity: 0 }, 100).promise();
            },

            fadeIn: function() {
                /**
                 * this.newContainer is the HTMLElement of the new Container
                 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
                 * Please note, newContainer is available just after newContainerLoading is resolved!
                 */

                var _this = this;
                var $el = $(this.newContainer);

                $(this.oldContainer).hide();

                $el.css({
                    visibility: 'visible',
                    opacity: 0
                });
                _this.done();

                $el.animate({ opacity: 1 }, 400, function() {});
            }
        });

        Barba.Pjax.getTransition = function() {
            return FadeTransition;
        };
    }

}

module.exports = { PageLoading };
