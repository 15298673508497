class Resizing {
    constructor() {
        this.resizeTimer = null;
        this.resizeInterval = 50;

        this.scrollbarMeasureTimer = null;
    }

    setup() {
        const rHandler = global.utils.throttled(this.resizeInterval, this.resizeHandler);
        if(window.attachEvent) {
            window.attachEvent('onresize', rHandler);
        }else if(window.addEventListener) {
            window.addEventListener('resize', rHandler, true);
        }else {
            //The browser does not support Javascript event binding
            window.onresize = rHandler;
        }

        this.resizeHandler();
    }

    onResize(c, t) {
        onresize = function() {
            clearTimeout(t);
            t = setTimeout(c, 100);
        };
        return c;
    }

    resizeHandler() {
        global.utils.updateSizeRefs();
        console.log(global.viewportWidth + " x " + global.viewportHeight);
        $('.resolution').html(global.viewportWidth + " x " + global.viewportHeight);

        /*if(this.scrollbarMeasureTimer !== undefined){
            clearTimeout(this.scrollbarMeasureTimer);
        }
        this.scrollbarMeasureTimer = setTimeout(global.utils.measureScrollBarWidth, this.resizeInterval*2);
        */

        if(global.viewportHeight <= 400){
            $('html').addClass('vp-short');
        }else{
            $('html').removeClass('vp-short');
        }

        $('.fullbleedGallery-content').height(global.viewportHeight);

        /*$('.workGridBlock').each(function(){
            var maxH = global.utils.getMaxHeight($(this).find('.entryBlock.reducedWidth .workGridBlock-contents'),false,false);
            $(this).find('.workGridBlock-item.letterBlock').css('min-height',maxH+'px');
        });*/

        $('.workCategoryNav-link').each(function(){
            $(this).css('min-width', $(this).find('span').outerWidth(true) + 4 +'px');
        });

        $('.workGalleryOverlay').css('min-height', global.viewportHeight+ "px");
        var offset = Math.max(20,(global.viewportHeight - $('.workGalleryHolder').outerHeight() - $('.workGalleryNavHolder').outerHeight()) / 2);
        $('.workGalleryOverlay').css('padding-top', offset + 15 +"px");

        $('.workGridBlock-letterChar img').css("margin-top", Math.floor($('.workGridBlock-letterChar').height()*0.024)+"px");
    }

}

module.exports = { Resizing };
