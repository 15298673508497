require("./styles/main.scss");

import { Utilities } from "./scripts/Utilities";

import { DocumentReady } from "./scripts/loading/1DocumentReady";
import { PageReady } from "./scripts/loading/2PageReady.js";
import { InitPage } from "./scripts/loading/3InitPage";
import { PageLoading } from "./scripts/loading/PageLoading";

import { Scrolling } from "./scripts/layout/Scrolling";
import { Resizing } from "./scripts/layout/Resizing";

const startDate = new Date();

global.utils = new Utilities();

const documentReady = new DocumentReady();
global.pageReady = new PageReady();
global.initPage = new InitPage();
const pageLoading = new PageLoading();

global.scrolling = new Scrolling();
global.resizing = new Resizing();


document.addEventListener("DOMContentLoaded", function() {
    documentReady.setupPageClasses();
    //documentReady.setupHeadroom();
    documentReady.setupNavigationLinks();
    documentReady.setupKeyboardEvents();

    scrolling.setup();
    resizing.setup();

    var allowBarba = false;
    if(window.location.href.indexOf("balanced") > -1 || window.location.href.indexOf("static.media") > -1){
        allowBarba = true;
    }
    if($('html').hasClass('ie')){
        allowBarba = false;
    }
    if(allowBarba){
        //documentReady.setupCookieBanner();
        pageLoading.setupBarba();
    }
});

window.onload = function() {
    global.pageReady.setup();
    global.initPage.setup();
};

/*const endDate = new Date();
const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
console.log(`App started in ${seconds} seconds`);*/
