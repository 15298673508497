const ScrollReveal = require("scrollreveal");

class PageReady {
    constructor() {
        this.pageReady = false;
        this.revealOptions = {
            origin:'bottom',
            distance:'30px',
            duration:800,
            delay:1,
            easing:'cubic-bezier(0.215, 0.61, 0.355, 1)',
            scale:1,
            opacity:0,
            mobile:true,
            reset:false,
            viewFactor:0.18,
            //container: '.main-container',
            beforeReveal: function (domEl) {
                global.resizing.resizeHandler();
                setTimeout(global.resizing.resizeHandler,10);
            },
            afterReveal : function(domEl) {
                if(domEl){
                    $(domEl).addClass('revealed');
                    $(domEl).removeClass('revealing');
                }
            },
            afterReset  : function(domEl) {
                if(domEl){
                    $(domEl).removeClass('revealed');
                }
            }
        };
    }

    setup() {
        (async() => {
            try {

                await this.delayedSetup();

            } catch (error) {
                console.error('(FROM ASYNC/AWAIT) Error cause is:', error);
            }
        })();

        this.updatePageClass();
        this.updateNavigation();
        $('body').addClass('page-ready');
        this.pageReady = true;
    }

    delayedSetup(){
        let scope = this;
        setTimeout(function() {
            $('html').removeClass('page-initial-load');

            //$('body').waitForImages(function(){
            scope.setupScrollReveal();
            //});

        }, 10);
    }

    setupScrollReveal(){
        if(!window.sr){
            window.sr = new ScrollReveal();
        }
        if($('.reveal').length > 0){
            window.sr.reveal( '.reveal', this.revealOptions );
        }
        if($('.staggered-reveal').length > 0){
            window.sr.reveal( '.staggered-reveal', this.revealOptions, 200);
        }
        if($('.side-reveal').length > 0){
            window.sr.reveal( '.side-reveal', {origin:'left'});
        }
        if($('.reveal-static').length > 0){
            window.sr.reveal( '.reveal-static', {duration:800, distance:'0px'} );
        }
    }

    updatePageClass(){
        if($('.pageTrack').length > 0){
            if($('.pageTrack').data('class')){
                if($('.pageTrack').data('class') != ""){
                    // removes any class on body that starts with "page-"
                    $('body').removeClass(function (index, className) {
                        return (className.match (/(^|\s)page-\S+/g) || []).join(' ');
                    });
                    $('body').addClass("page-"+$('.pageTrack').data('class'));
                }
            }
        }
    }

    updateNavigation(){
        //console.log($('.pageTrack').data('menuclass'));
        if($('.pageTrack').length > 0){
            if($('.pageTrack').data('menuclass')){
                if($('.pageTrack').data('menuclass') != ""){
                    $('nav .menu .selected').removeClass('selected');
                    $('nav .menu .'+$('.pageTrack').data('menuclass')).addClass('selected');
                }
            }
        }
    }
}

module.exports = { PageReady };
